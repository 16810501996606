<template>
  <Navbar></Navbar>
  <div :class="['grid', 'm-2', darkMode ? 'dark-mode' : 'light-mode']">
    <div v-for="app in apps" :key="app.title" class="col-12 md:col-6 lg:col-3">
      <PCard>
        <template #header>
          <img :alt="app.title" :src="app.image" width="100%" height="250px" />
        </template>
        <template #title>
          <div class="text-center">{{ app.title }}</div>
        </template>

        <template #content>
          <p class="m-0 text-center">{{ app.content }}</p>
        </template>

        <template #footer>
          <div class="flex gap-4 mt-1">
            <Button label="Open" class="w-full" as="a" :href="app.url" />
          </div>
        </template>
      </PCard>
    </div>
    <div class="col-12 md:col-6 lg:col-3">
      <PCard>
        <template #header> &nbsp; </template>
        <template #title>
          <div class="text-center">Netsuite account</div>
        </template>

        <template #content>
          <p class="m-0 text-center">
            Allocate an account to login into NetSuite
          </p>
        </template>

        <template #footer>
          <div class="flex gap-4 mt-1">
            <NetsuiteAccount />
          </div>
        </template>
      </PCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import NetsuiteAccount from "@/components/NetsuiteAccount.vue";
import Navbar from "@wovar/common/components/Navbar.vue";
import { useDarkModeManager } from "@wovar/common/dark-mode/store.js";

const { darkMode } = useDarkModeManager();

const apps = ref([
  {
    title: "Verzendcentrale",
    content: "Checking orders, print labels and send packages.",
    image: "/assets/verzendcentrale.png",
    url: `${location.protocol}//vc.${location.host}`,
  },
  {
    title: "Box packing",
    content: "More boxes on the transport belt!",
    image: "/assets/boxpacking.webp",
    url: `${location.protocol}//boxes.${location.host}`,
  },
  // {
  //   title: "Error Lane",
  //   content: "Errorlane and actions.",
  //   image: "/assets/errorlane.png",
  //   url: `${location.protocol}//errorlane.${location.host}`,
  // },
  {
    title: "Order Picking",
    content: "Manage order picking flow.",
    image: "/assets/orderpicking.png",
    url: `${location.protocol}//orderpicking.${location.host}`,
  },
  // {
  //   title: "Multi Order Picking",
  //   content: "View Non Maas Assignments Screen.",
  //   image: "/assets/multiorderpicking.png",
  //   url: `${location.protocol}//multiorderpicking.${location.host}`,
  // },
  // {
  //   title: "Inventory Adjustment",
  //   content: "Adjust inventory of an item of a specific bin.",
  //   image: "/assets/inventoryadjustment.png",
  //   url: `${location.protocol}//inventoryadjustment.${location.host}`,
  // },
  {
    title: "User management",
    content: "Manage users and the apps they have access to.",
    url: `${location.protocol}//users.${location.host}`,
  },
]);
</script>

<style scoped lang="css">
.card-image-container {
  height: 200px;
  width: 100%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.p-card) {
  height: 470px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

:deep(.p-card .p-card-header) {
  height: 300px;
  overflow: hidden;
}

:deep(.p-card .p-card-body) {
  position: relative;
  height: 200px;
  padding: 0;
}

:deep(.p-card .p-card-content) {
  padding: 1rem;
}

:deep(.p-card .p-card-footer) {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;
  padding: 0 1rem;
  background: inherit;
}

:deep(.p-card:hover) {
  transform: translateY(-2px);
}

:deep(.p-card:hover .p-card-content) {
  background-color: rgba(0, 0, 0, 0.02);
}

:deep(.p-button.p-button-sm) {
  padding: 0.4rem 0.8rem;
  font-size: 0.875rem;
}

:deep(.p-button:focus) {
  box-shadow:
    0 0 0 2px #fff,
    0 0 0 4px var(--primary-color);
}

:deep(.p-button .p-button-icon) {
  font-size: 0.875rem;
  margin-left: 0.25rem;
}
</style>
